import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MediaSourceTypes } from '@desquare/enums';
import { CurrentUserService } from '@desquare/services';
import { MediaStore } from '@desquare/stores';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    TranslatePipe,
    NgbTooltip,
  ],
  selector: 'app-media-filter-button-group',
  templateUrl: './media-filter-button-group.component.html',
  styleUrls: ['./media-filter-button-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaFilterButtonGroupComponent {
  mediaStore = inject(MediaStore);
  currentUserService = inject(CurrentUserService);
  MediaSourceTypes = MediaSourceTypes;
  PlaylistMediaLibrary = input<boolean>(false);

  filterStates = this.mediaStore.mediaTypeFilter;

  get isSuperAdmin() {
    return this.currentUserService.isSuperAdmin;
  }
}
