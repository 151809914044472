<div class="d-flex align-items-center justify-content-end">
  <div class="me-2 text-truncate">{{ 'FILTER_CONTENT_TYPE' | translate }}:</div>
  @if (!PlaylistMediaLibrary()) {
    <div
      class="btn-group btn-group-sm btn-group-toggle me-2"
      [ngbTooltip]="'MEDIA.MEDIA_USED_IN_PLAYLISTS_TT' | translate"
    >
      <input
        class="btn-check"
        type="radio"
        (click)="mediaStore.toggleUsedFilter('used')"
        [checked]="mediaStore.filters().used === true"
        id="usedMedia"
        autocomplete="off"
      />
      <label class="btn btn-outline-light" for="usedMedia">
        {{ 'MEDIA.MEDIA_USED' | translate }}
      </label>
      <input
        class="btn-check"
        type="radio"
        (click)="mediaStore.toggleUsedFilter('unused')"
        [checked]="mediaStore.filters().used === false"
        id="unusedMedia"
        autocomplete="off"
      />
      <label class="btn btn-outline-light" for="unusedMedia">
        {{ 'MEDIA.MEDIA_UNUSED' | translate }}
      </label>
    </div>
  }
  <div
    class="mx-1 d-flex align-items-center form-check form-switch py-1 rounded-pill btn-group"
    [ngClass]="filterStates().image ? 'bg-success' : ''"
  >
    <input
      class="form-check-input"
      type="checkbox"
      role="switch"
      [ngModel]="filterStates().image"
      (click)="mediaStore.toggleMediaTypeFilter('image')"
      id="filterImage"
    />
    <label class="px-2 form-check-label" for="filterImage">{{
      'IMAGE' | translate
    }}</label>
  </div>

  <div
    class="mx-1 d-flex align-items-center form-check form-switch py-1 rounded-pill"
    [ngClass]="filterStates().video ? 'bg-success' : ''"
  >
    <input
      class="form-check-input"
      type="checkbox"
      role="switch"
      [ngModel]="filterStates().video"
      (click)="mediaStore.toggleMediaTypeFilter('video')"
      id="filterVideo"
    />
    <label class="px-2 form-check-label" for="filterVideo">{{
      'VIDEO' | translate
    }}</label>
  </div>

  <div
    class="ms-1 d-flex align-items-center form-check form-switch py-1 rounded-pill"
    [ngClass]="filterStates().templateImage ? 'bg-success' : ''"
  >
    <input
      class="form-check-input"
      type="checkbox"
      role="switch"
      [ngModel]="filterStates().templateImage"
      (click)="mediaStore.toggleMediaTypeFilter('templateImage')"
      id="filterImageTemplate"
    />
    <label class="px-2 form-check-label" for="filterImageTemplate">{{
      'IMAGE_TEMPLATE' | translate
    }}</label>
  </div>

  <div
    class="ms-1 d-flex align-items-center form-check form-switch py-1 rounded-pill"
    [ngClass]="filterStates().templateVideo ? 'bg-success' : ''"
  >
    <input
      class="form-check-input"
      type="checkbox"
      role="switch"
      [ngModel]="filterStates().templateVideo"
      (click)="mediaStore.toggleMediaTypeFilter('templateVideo')"
      id="filterVideoTemplate"
    />
    <label class="px-2 form-check-label" for="filterVideoTemplate">{{
      'VIDEO_TEMPLATE' | translate
    }}</label>
  </div>
  @if (isSuperAdmin) {
    <div
      class="ms-1 d-flex align-items-center form-check form-switch py-1 rounded-pill"
      [ngClass]="filterStates().playlistImage ? 'bg-success' : ''"
    >
      <input
        class="form-check-input"
        type="checkbox"
        role="switch"
        [ngModel]="filterStates().playlistImage"
        (click)="mediaStore.toggleMediaTypeFilter('playlistImage')"
        id="filterPlaylistImage"
      />
      <label class="px-2 form-check-label" for="filterPlaylistImage">{{
        'PLAYLIST_IMAGE' | translate
      }}</label>
    </div>
    <div
      class="ms-1 d-flex align-items-center form-check form-switch py-1 rounded-pill"
      [ngClass]="filterStates().playlistVideo ? 'bg-success' : ''"
    >
      <input
        class="form-check-input"
        type="checkbox"
        role="switch"
        [ngModel]="filterStates().playlistVideo"
        (click)="mediaStore.toggleMediaTypeFilter('playlistVideo')"
        id="filterPlaylistVideo"
      />
      <label class="px-2 form-check-label" for="filterPlaylistVideo">{{
        'PLAYLIST_VIDEO' | translate
      }}</label>
    </div>
  }
</div>
