import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  signal,
} from '@angular/core';
import { AssetItem, VideoAsset, ImageAsset, AssetType } from '@designage/gql';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { getOptimizedUrl } from '@desquare/utils';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [TranslatePipe],
  selector: 'app-thumbnail-preview-dialog',
  template: `<div class="modal-container">
    <div class="modal-body">
      @switch (assetItem()?.__typename) {
        @case ('ImageAsset') {
          <img class="modal-content" [src]="imageUri()" />
        }
        @case ('VideoAsset') {
          <video
            class="modal-content"
            [src]="videoUri()"
            controls="false"
            autoplay
          ></video>
        }
        @default {
          <h5 class="text-center text-warning">
            {{ 'TYPE_IS_UNSUPPORTED' | translate }}
          </h5>
        }
      }
    </div>
  </div> `,
  styles: [
    `
      .modal-content {
        max-height: 80vh;
        max-width: 90vh;
        object-fit: contain;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThumbnailPreviewDialogComponent {
  assetItem = signal<AssetItem | undefined>(undefined);
  modal = inject(NgbActiveModal);

  videoUri = computed(() => {
    const videoUri = (this.assetItem() as VideoAsset).uri;
    return getOptimizedUrl(AssetType.Video, videoUri);
  });

  imageUri = computed(() => {
    const imageUri = (this.assetItem() as ImageAsset).uri;
    return getOptimizedUrl(AssetType.Image, imageUri);
  });
}
